// import './App.css';
import React from "react";
import { Amplify } from "aws-amplify";
// import AdversaryComponent from "./AdversaryComponent";
import config from "./config";
import "@cloudscape-design/global-styles/index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Authenticator from "./Authenticator";

import ViewComponent from "./ViewComponent";

import { ViewContextProvider } from "./ViewContext";

import QueryLanding from "./QueryLanding";

Amplify.configure(config);

function App() {
  return (
    <Authenticator>
      <ViewContextProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/mouse/:view/:param"
              element={<ViewComponent />}
            ></Route>
            <Route
              path="/query/:view/:param"
              element={<ViewComponent />}
            ></Route>
            <Route path="/query/:view" element={<ViewComponent />}></Route>

            <Route path="/" element={<QueryLanding />}></Route>
          </Routes>
        </BrowserRouter>
      </ViewContextProvider>
    </Authenticator>
  );
}

export default App;
