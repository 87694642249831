import React from "react";
import PropTypes from "prop-types";
import {
  AppLayout,
  Button,
  ContentLayout,
  Grid,
  Input,
  SpaceBetween,
  SplitPanel,
  TextContent,
} from "@cloudscape-design/components";
import DetailsComponent from "./common/DetailsComponent";
import { appLayoutLabels } from "./common/AriaConstants";
import { SPLIT_PANEL_I18NSTRINGS } from "./common/AriaConstants";
import { useNavigate, useParams } from "react-router-dom";
import NavComponent, { generateCatUrl } from "./common/Navigation";

import { withViews, getView, param_required } from "./ViewContext";
import SectionComponent from "./SectionComponent";
import HeaderComponent from "./Header";

function withParams(Component) {
  return (props) => (
    <Component {...props} params={useParams()} navigation={useNavigate()} />
  );
}

class ViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: null,

      // This is to force the child components to update
      change_key: true,

      error: null,
      detailsOpen: false,
      param: props.params.param ? decodeURIComponent(props.params.param) : null,
      selectedItem: { name: "" },
      selectedItemModel: {},
      navOpen: false,
      view: getView(props.views, props.params.view),
      hasQuery: false,
      paramRequired: param_required(
        getView(this.props.views, this.props.params.view)
      ),
    };
  }
  queryCat(param) {
    // let encoded_param = encodeURIComponent(param);
    if (
      this.state.paramRequired &&
      (!this.props.params.param || this.props.params.param !== param)
    ) {
      console.log("Param: " + this.props.params.param);
      console.log("Setting new URL");

      this.props.navigation(generateCatUrl(this.state.view.id, param));
    }
    this.setState({ hasQuery: true, change_key: !this.state.change_key });
  }
  handleKeyDown = (e) => {
    if (e.detail.key === "Enter") {
      // this.setState({ data: null });
      this.queryCat(this.state.param);
    }
  };
  async componentDidMount() {
    if (this.state.param || !this.state.paramRequired) {
      this.queryCat(this.state.param);
    }
  }
  setToolsOpen = (event) => this.setState({ toolsOpen: event });
  updateValue = (event) => {
    this.setState({ param: event.detail.value });
  };

  tableItemSelected = (event, model) => {
    console.log("Table Item Selected");
    this.setState({
      selectedItem: event[0],
      detailsOpen: true,
      selectedItemModel: model,
    });
  };
  getParameter = (view) => {
    // This needs to be updated when the API updates
    if (view && view.parameters) return view.parameters[0].label;
    return null;
  };
  renderSearch = () => (
    <Grid gridDefinition={[{ colspan: 2 }, { colspan: 8 }, { colspan: 2 }]}>
      <TextContent>
        <h4>{this.getParameter(this.state.view)}</h4>
      </TextContent>

      <Input
        value={this.state.param}
        onChange={(detail) => {
          this.updateValue(detail);
        }}
        onKeyDown={this.handleKeyDown}
        placeholder={`Enter ${this.getParameter(this.state.view)}`}
      ></Input>

      <div>
        <Button
          variant="primary"
          onClick={() => this.queryCat(this.state.param)}
        >
          Search
        </Button>
      </div>
    </Grid>
  );
  renderContent = () => {
    if (!this.state.hasQuery) return null;

    return this.state.view.sections.map((section) => {
      return (
        <SectionComponent
          key={`${section}+${this.state.change_key}`}
          view={this.state.view}
          section={section}
          param={this.state.param}
          tableItemSelected={this.tableItemSelected}
        />
      );
    });
  };

  render() {
    return (
      <AppLayout
        maxContentWidth={Number.MAX_VALUE}
        contentType="table"
        content={
          <ContentLayout header={<HeaderComponent />}>
            <SpaceBetween size="s">
              <TextContent>
                <h1>{this.state.view.title}</h1>
              </TextContent>
              {this.state.paramRequired && this.renderSearch()}
              {this.renderContent()}
            </SpaceBetween>
          </ContentLayout>
        }
        onToolsChange={({ detail }) => this.setToolsOpen(detail.open)}
        toolsHide={true}
        navigationOpen={this.state.navOpen}
        onNavigationChange={({ detail }) =>
          this.setState({ navOpen: detail.open })
        }
        navigation={<NavComponent href={`/query/${this.state.view.id}`} />}
        splitPanelOpen={this.state.detailsOpen}
        onSplitPanelToggle={(event) =>
          this.setState({ detailsOpen: event.open })
        }
        splitPanel={
          <SplitPanel header="Details" i18nStrings={SPLIT_PANEL_I18NSTRINGS}>
            <DetailsComponent
              canEdit={false}
              model={this.state.selectedItemModel}
              data={this.state.selectedItem}
              submitCallback={() => console.log("submitcallback called")}
            />
          </SplitPanel>
        }
        ariaLabels={appLayoutLabels}
      ></AppLayout>
    );
  }
}
ViewComponent.propTypes = {
  params: PropTypes.object,
  views: PropTypes.array,
  navigation: PropTypes.func,
};
export default withViews(withParams(ViewComponent));
