import React from "react";
import PropTypes from "prop-types";
import {
  ColumnLayout,
  Box,
  SpaceBetween,
  Button,
  Select,
  Input,
  Form,
  Header,
  Checkbox,
  AttributeEditor,
  Link,
} from "@cloudscape-design/components";
import { generateCatUrl } from "./Navigation";

const generateAttributeItems = (item) =>
  item.map((value, index) => {
    return { key: value, index: index };
  });
const generateAttributeDefinition = () => {
  return [
    {
      control: (item) => <Input value={item.key} placeholder="new value" />,
    },
  ];
};
const AttributeSelector = (props) => {
  if (!props.item) return <div></div>;
  var parsed_item = props.item;
  if (props.item instanceof String || typeof props.item === "string") {
    parsed_item = JSON.parse(props.item);
  }
  if (props.edit)
    return (
      <AttributeEditor
        items={generateAttributeItems(parsed_item)}
        definition={generateAttributeDefinition()}
        addButtonText={"Add new " + props.label}
        onAddButtonClick={() => props.onAddNewItem()}
      ></AttributeEditor>
    );
  return (
    <ul>
      {parsed_item.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  );
};
AttributeSelector.propTypes = {
  edit: PropTypes.bool.isRequired,
  item: PropTypes.object,
  label: PropTypes.string.isRequired,
  onAddNewItem: PropTypes.func.isRequired,
};
class DetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };

    if (this.props.data) {
      Object.keys(this.props.data).forEach((key) => {
        if (this.props.data[key]) {
          this.state[key] = this.props.data[key].value;
        } else {
          this.state[key] = "nodef";
        }
      });
    }
  }
  updateValue = (key, value) => {
    console.log("Updating " + key + " to value " + value);
    this.setState({ [key]: value });
  };
  handleSubmit = () => {
    let returnData = {};
    Object.keys(this.props.model).forEach(
      // Cannot just do a if (this.state[key] check as the value could be a boolean)
      (key) =>
        (returnData[key] =
          this.state[key] == null ? this.props.data[key] : this.state[key])
    );

    console.log(returnData);
    this.props.submitCallback(returnData);
    this.setState({ edit: false });
  };
  renderInput = (label, value, meta) => {
    if (!this.state.edit) {
      if (meta.type === "text") {
        let text = "NULL";
        /// Need to specifically check for null here as the value could be falseqq
        if (value !== null && typeof value !== "undefined") {
          text = value.toString();
        }
        return <div>{text}</div>;
      }
      if (meta.type === "link")
        return (
          <Link
            external
            variant="primary"
            href={generateCatUrl(meta.view, value)}
          >
            {value}
          </Link>
        );
    }
    if (meta.type === "externallink")
      return (
        <Link external variant="primary" href={value}>
          {label}
        </Link>
      );
    if (meta.type === "text")
      return (
        <Input
          value={this.state[label] || value}
          onChange={(detail) => {
            this.updateValue(label, detail.detail.value);
          }}
        ></Input>
      );
    if (meta.type === "enum")
      return (
        <Select
          selectedOption={{ value: this.state[label] || value }}
          onChange={(detail) =>
            this.updateValue(label, detail.detail.selectedOption.value)
          }
          options={meta.options}
          disabled={!this.state.edit}
          selectedAriaLabel="Selected"
        />
      );
    if (meta.type === "boolean") {
      if (!value) {
        return "No Value";
      }
      return (
        <Checkbox
          onChange={({ detail }) => {
            console.log(detail.checked);

            this.updateValue(label, detail.checked);
          }}
          checked={this.state[label] != null ? this.state[label] : value}
          disabled={!this.state.edit}
        />
      );
    }
    if (meta.type === "list")
      return (
        <AttributeSelector
          item={this.state[label] != null ? this.state[label] : value}
          edit={this.state.edit}
          label={label}
          onAddNewItem={() => this.updateValue(label, [...value, {}])}
        ></AttributeSelector>
      );
  };
  save = () => console.log("save called");
  toggleEdit = () => this.setState({ edit: !this.state.edit });
  header = () => {
    if (!this.props.canEdit) return null;
    return (
      <Header
        variant="h1"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            {!this.state.edit && (
              <Button variant="primary" onClick={this.toggleEdit}>
                Edit
              </Button>
            )}
            {this.state.edit && (
              <Button onClick={this.toggleEdit}>Cancel</Button>
            )}
            {this.state.edit && (
              <Button variant="primary" onClick={this.handleSubmit}>
                Save
              </Button>
            )}
          </SpaceBetween>
        }
      >
        {this.props.title}
      </Header>
    );
  };

  renderElement = (label, value, meta, full_data) => {
    // if (this.isLabelHidden(label)) {
    //   return "";
    // }

    if (meta.type == "externallink") {
      console.log("Replacing external link value");
      value = meta.dest.replace("{id}", full_data[meta.id]);
    }
    return (
      <div key={label}>
        <Box variant="awsui-key-label">{label}</Box>
        {this.renderInput(label, value, meta)}
      </div>
    );
  };
  render() {
    if (!this.props.data) return "";
    return (
      <Form header={this.header()}>
        <ColumnLayout columns={4} variant="text-grid">
          {
            // this.props.formModel.map(m => this.renderElement(m))
            Object.keys(this.props.model).map((key) =>
              this.renderElement(
                this.props.model[key].label || key,
                this.props.data[key],
                this.props.model[key],
                this.props.data
              )
            )
          }
        </ColumnLayout>
      </Form>
    );
  }
}
DetailsComponent.propTypes = {
  data: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  submitCallback: PropTypes.func,
  title: PropTypes.string,
};
DetailsComponent.defaultProps = {
  canEdit: true,
};
export default DetailsComponent;
