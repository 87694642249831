/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { Auth } from "aws-amplify";

const getapiendpoint = () => {
  if (
    window.location.host.includes("beta") ||
    window.location.host.includes("localhost")
  ) {
    return "https://api.catbeta.payments-security.amazon.dev";
  }
  return "https://api.cat.payments-security.amazon.dev";
};

const getauthdomain = () => {
  if (
    window.location.host.includes("beta") ||
    window.location.host.includes("localhost")
  ) {
    return "act-dashboard-beta.auth.us-east-1.amazoncognito.com";
  }
  return "act-dashboard.auth.us-east-1.amazoncognito.com";
};
const getuserpoolid = () => {
  if (
    window.location.host.includes("beta") ||
    window.location.host.includes("localhost")
  ) {
    return "us-east-1_hb3zH7trK";
  }
  return "us-east-1_vY9sbtxzK";
};
const getuserwebclientid = () => {
  if (
    window.location.host.includes("beta") ||
    window.location.host.includes("localhost")
  ) {
    return "53ga3o92akhpc3ttj7aka1mjra";
  }
  return "4kpqeeccinbtgof988tb8lhk2e";
};
const oauth = {
  domain: getauthdomain(), // check domain name under app integration
  redirectSignIn: window.location.protocol + "//" + window.location.host, //'http://dev.cat.payments-security.amazon.dev', // this should be added in the appclient settings as well
  redirectSignOut: window.location.protocol + "//" + window.location.host,
  scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"], // important
  responseType: "code",
};
const amplify = {
  Auth: {
    mandatorySignId: true,
    region: "us-east-1",
    userPoolId: getuserpoolid(),
    userPoolWebClientId: getuserwebclientid(),
    oauth: oauth,
  },
  API: {
    endpoints: [
      {
        name: "API",
        endpoint: getapiendpoint(),
        custom_header: async () => {
          var session = await Auth.currentSession();
          var token = session.getIdToken().getJwtToken();
          return { Authorization: token };
        },
      },
    ],
  },
};
export default amplify;
