import React, { useState } from "react";
import PropTypes from "prop-types";
import { RestAPI as API } from "@aws-amplify/api-rest";
const ViewContext = React.createContext({
  views: [],
});

const ViewContextProvider = ({ children }) => {
  const [views, setView] = useState(null);

  if (!views) {
    console.log("getting view state");
    API.get("API", "/views").then((response) => {
      console.log("view state response");
      setView({ views: response });
    });
  }
  if (views) {
    return (
      <ViewContext.Provider value={views}>{children}</ViewContext.Provider>
    );
  } else return null;
};
ViewContextProvider.propTypes = {
  children: PropTypes.object,
};

const withViews = (Child) => (props) =>
  (
    <ViewContext.Consumer>
      {(context) => <Child {...props} {...context} />}
      {/* Another option is:  {context => <Child {...props} context={context}/>}*/}
    </ViewContext.Consumer>
  );

export { ViewContextProvider, withViews };

export const getView = (views, id) => {
  if (!views) return null;
  const filtered_views = views.filter((item) => {
    return item.id === id;
  });
  return filtered_views[0];
};

export const param_required = (view) => {
  if (view && "parameters" in view && view.parameters) return true;
  return false;
};
