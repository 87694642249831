import { RestAPI as API } from "@aws-amplify/api-rest";
import React from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Container,
  Header,
  Spinner,
} from "@cloudscape-design/components";
import DetailsComponent from "./common/DetailsComponent";

import TableComponent from "./common/TableComponent";

class SectionComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      data: null,
    };
  }
  fetchSection(forceRefresh) {
    // let encoded_param = encodeURIComponent(param);

    this.setState({ loading: true, error: null, sections: null });
    // this.props.history.replace({pathname: `/products/1`})
    if (!this.state.sections || forceRefresh) {
      const searchParams = new URLSearchParams();
      searchParams.append("param", encodeURIComponent(this.props.param));
      searchParams.append("view", this.props.view.id);
      searchParams.append("section", this.props.section);
      const url = "/query?" + searchParams.toString(); // "type=all&query=coins"
      API.get("API", url)
        .then((response) => {
          // console.log("Response Recieved");
          this.setState({
            data: response,

            loading: false,
            error: null,
          });
        })
        .catch((error) => this.setState({ error: error, loading: false }));
    }
  }

  async componentDidMount() {
    if (this.state.param || !this.state.paramRequired) {
      this.fetchSection();
    }
  }

  tableItemSelected = (event, model) => {
    this.setState({
      selectedItem: event[0],
      detailsOpen: true,
      selectedItemModel: model,
    });
  };

  renderContainer = (view, data, title, model, query) => {
    if (view === "detail") {
      return (
        <Container
          header={<Header> {title}</Header>}
          variant="stacked"
          key="details"
        >
          <DetailsComponent
            canEdit={false}
            model={model}
            data={data}
            submitCallback={() =>
              console.log("submit callback called but doesn't do anything")
            }
          />
        </Container>
      );
    }
    if (view === "table") {
      return (
        <TableComponent
          model={model}
          data={data}
          itemSelected={(event) => this.props.tableItemSelected(event, model)}
          title={title}
          key={title}
          query={query}
        />
      );
    }
  };
  render = () => {
    {
      this.renderAlert(this.state.error);
    }
    if (this.state.loading) {
      return <Spinner size="large" />;
    } else if (!this.state.data) return null;
    else {
      return this.renderContainer(
        this.state.data.view,
        this.state.data.data,
        this.state.data.title,
        this.state.data.model.properties,
        this.state.data.query
      );
    }
  };

  renderAlert = (error) => {
    if (error != null)
      return (
        <Alert
          type="error"
          dismissible
          onDismiss={() => this.setState({ error: null })}
        >
          {error}
        </Alert>
      );
  };
}
SectionComponent.propTypes = {
  param: PropTypes.string,
  view: PropTypes.object,
  section: PropTypes.string,
  tableItemSelected: PropTypes.func,
};
export default SectionComponent;
