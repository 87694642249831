import React from "react";
import { CSVLink } from "react-csv";
import Table from "@cloudscape-design/components/table";
import PropTypes from "prop-types";
import { PROPERTY_FILTERING_I18N_CONSTANTS } from "./AriaConstants";
import {
  ExpandableSection,
  Header,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Pagination } from "@cloudscape-design/components";
import { CollectionPreferences } from "@cloudscape-design/components";
import { useLocalStorage } from "./localStorage";
import PropertyFilter from "@cloudscape-design/components/property-filter";
import { generateCatUrl } from "./Navigation";
import ReactJson from "react-json-view";
const generateCell = (value, model, full_data, key) => {
  if (value == null && model.type != "externallink") return "";
  if (model.type == "text" || model.type == "boolean") return value;
  if (model.type == "json") {
    return (
      <ReactJson src={value} collapsed={true} name="statements"></ReactJson>
    );
  }

  if (model.type == "link")
    return (
      <Link external variant="primary" href={generateCatUrl(model.view, value)}>
        {value}
      </Link>
    );
  if (model.type == "externallink") {
    value = model.dest.replace("{id}", full_data[model.id]);
    return (
      <Link external variant="primary" href={value}>
        {key}
      </Link>
    );
  }
};
const generateColumnDefs = (model) => {
  return Object.keys(model).map((key) => {
    return {
      key: key,
      id: key,
      header: model[key].label || key,
      cell: (e) => generateCell(e[key], model[key], e, key),
      sortingField: key,
    };
  });
};
const generateFilterProperties = (model) => {
  return Object.keys(model).map((key) => {
    return {
      propertyLabel: key,
      key: key,
      // groupValuesLabel: key,
      operators: [":", "!:", "=", "!="],
    };
  });
};

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 Items" },
  { value: 30, label: "30 Items" },
  { value: 50, label: "50 Items" },
  { value: 100, label: "100 Items" },
];
export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
  />
);
Preferences.propTypes = {
  preferences: PropTypes.object.isRequired,
  setPreferences: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  pageSizeOptions: PropTypes.object,
};
export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: [
    "id",
    "domainName",
    "deliveryMethod",
    "sslCertificate",
    "status",
    "state",
  ],
  wrapLines: false,
};
function TableComponent(props) {
  const getFilterCounterText = (count) =>
    `${count} ${count === 1 ? "match" : "matches"}`;

  const columnDefs = generateColumnDefs(props.model);
  const [preferences, setPreferences] = useLocalStorage(
    `${props.title}-preferences`,
    DEFAULT_PREFERENCES
  );

  const filterProperties = generateFilterProperties(props.model);
  const {
    items,
    filteredItemsCount,
    collectionProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(props.data || [], {
    propertyFiltering: {
      filteringProperties: filterProperties,

      empty: <div> No Data </div>,
      fields: ["name"],
      noMatch: <div> No Match </div>,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  const { selectedItems } = collectionProps;
  return (
    <Table
      {...collectionProps}
      // resizableColumns
      onSelectionChange={(detail) => {
        collectionProps.onSelectionChange(detail);
        if (props.itemSelected != null) {
          props.itemSelected(detail.detail.selectedItems);
        }
      }}
      wrapLines={preferences.wrapLines}
      columnDefinitions={columnDefs}
      selectedItems={selectedItems}
      items={items}
      // resizableColumns={true}
      loadingText="Loading resources"
      selectionType="single"
      // trackBy="employee_login"
      //
      filter={
        <PropertyFilter
          i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
          {...propertyFilterProps}
          countText={getFilterCounterText(filteredItemsCount)}
          expandToViewport={true}
        />
      }
      header={
        <Header
          counter={
            props.data
              ? "(" + items.length + "/ " + props.data.length + ")"
              : "(" + items.length + ")"
          }
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <ExpandableSection headerText="Query">
                <div style={{ "white-space": "pre-line" }}>{props.query}</div>
              </ExpandableSection>

              {props.data && (
                <ExpandableSection headerText="CSV">
                  <CSVLink data={props.data} filename={`${props.title}.csv`}>
                    Download CSV
                  </CSVLink>
                </ExpandableSection>
              )}
            </SpaceBetween>
          }
        >
          {props.title}
        </Header>
      }
      pagination={
        <Pagination
          {...paginationProps}
          ariaLabels={{
            nextPageLabel: "Next page",
            previousPageLabel: "Previous page",
            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
          }}
        />
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
        />
      }
    />
  );
}
TableComponent.propTypes = {
  model: PropTypes.object.isRequired,
  data: PropTypes.array,
  itemSelected: PropTypes.func,
  title: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
};
export default TableComponent;
