import React from "react";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
class Authenticator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
    };
  }

  async componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.setState({
          loggedIn: true,
        });
      })
      .catch((err) => {
        console.error(err);
        try {
          this.tryLogin().then();
        } catch {
          console.log("failed");
        }
      });
  }

  async tryLogin() {
    await Auth.federatedSignIn();
  }

  render() {
    if (this.state.loggedIn) {
      return this.props.children;
    }
    return <div>Not logged in</div>;
  }
}
Authenticator.propTypes = {
  children: PropTypes.object,
};
export default Authenticator;
