import React from "react";
import PropTypes from "prop-types";
import { AppLayout, ContentLayout } from "@cloudscape-design/components";
import { withViews } from "./ViewContext";
import NavComponent from "./common/Navigation";
import HeaderComponent from "./Header";
class QueryLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navOpen: false,
    };
  }
  async componentDidMount() {
    console.log("Component Did Mount Called on landing page");

    if (!this.state.navOpen) {
      this.setState({ navOpen: true });
    }
  }
  render() {
    return (
      <AppLayout
        contentType="table"
        content={
          <ContentLayout header={<HeaderComponent />}>
            <div>Select a query from the menu</div>
          </ContentLayout>
        }
        onToolsChange={({ detail }) => this.setToolsOpen(detail.open)}
        toolsHide={true}
        navigationOpen={this.state.navOpen}
        onNavigationChange={({ detail }) =>
          this.setState({ navOpen: detail.open })
        }
        navigation={<NavComponent />}
      ></AppLayout>
    );
  }
}
QueryLanding.propTypes = {
  views: PropTypes.array,
};
export default withViews(QueryLanding);
