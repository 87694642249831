import React from "react";
import Header from "@cloudscape-design/components/header";
import { Auth } from "aws-amplify";

export default class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      family_name: "unknown",
      given_name: "unknown",
      email: "unknown",
    };
  }

  async componentDidMount() {
    Auth.currentAuthenticatedUser().then((user) => {
      this.setState({
        user: user,
        family_name: user.attributes.family_name,
        given_name: user.attributes.given_name,
        email: user.attributes.email,
      });
    });
  }
  get_alias = () => this.state.email.replace("@amazon.com", "");
  render() {
    console.log("header called");
    return (
      <Header
        actions={
          <div>
            {this.state.given_name} {this.state.family_name} ({this.get_alias()}
            )
          </div>
        }
      >
        CAT Explorer
        <br />
        <div style={{ fontSize: "small" }}>
          Created by the SDO Threat Operations Research and Monitoring (STORM)
          Team
        </div>
      </Header>
    );
  }
}
