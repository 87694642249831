import React from "react";
import PropTypes from "prop-types";

import { SideNavigation } from "@cloudscape-design/components";
import { withViews } from "../ViewContext";

// const baseNav = [
//   {
//     type: "section",
//     text: "Adversary Coverage Table",
//     items: [
//       { type: "link", text: "Adversaries", href: "/" },
//       { type: "link", text: "TTPs", href: "/ttps" },
//     ],
//   },
// ];
export const generateCatUrl = (view, param) => {
  return `/query/${view}/${encodeURIComponent(param)}`;
};
const generateNavItems = (response) => {
  if (!response) return {};
  const mouseItems = response.map((item) => {
    return {
      type: "link",
      text: item.title,
      href: `/query/${item.id}`,
    };
  });
  const viewNav = [
    {
      type: "section",
      text: "Views",
      items: mouseItems,
    },
  ];
  return [/*...baseNav,*/ ...viewNav];
};
class NavComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: null,
    };
  }
  async componentDidMount() {
    console.log("Navigation Component Did Mount Called");
  }
  componentDidUpdate = () => {
    if (this.props.views && !this.state.navItems) {
      console.log("setting new nav items");
      this.setState({ navItems: generateNavItems(this.props.views) });
    }
  };
  render = () => {
    console.log;
    if (this.state.navItems) {
      return (
        <SideNavigation
          activeHref={this.props.href}
          items={this.state.navItems}
        />
      );
    }
  };
}
NavComponent.propTypes = {
  href: PropTypes.string,
  views: PropTypes.array,
};
export default withViews(NavComponent);
